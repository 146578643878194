
import React from "react";
import { Container} from "reactstrap";
import DemoNavbar from "./HomeNavbar";
import Carousel from "./Carrucel";
import Download from "./inicio.js";

class Index extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
        <Carousel/>
          <section className="section section-components">
            <Container>
              <Download />
            </Container>         
          </section>   
        </main>
      </>
    );
  }
}

export default Index;
