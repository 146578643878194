import React, { useState, useEffect } from 'react';
import {Form,FormGroup,Label,Input,Button,Modal,ModalHeader,ModalBody,ModalFooter} from 'reactstrap';

const UserForm = ({ isOpen, toggle, onSubmit, initialValues }) => {
  const [formData, setFormData] = useState(initialValues || {});
  useEffect(() => {
    setFormData(initialValues || {});
  }, [initialValues, isOpen]); // Agregar isOpen como dependencia

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    onSubmit(formData);
  };

  const handleToggle = () => {
    if (!isOpen) {
      setFormData({}); // Reiniciar el estado del formulario cuando se cierra el modal
    }
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={handleToggle}>
      <Form onSubmit={handleSubmit}>
        <ModalHeader toggle={handleToggle}>{initialValues ? 'Editar Usuario' : 'Crear Usuario'}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="user">Usuario</Label>
            <Input
              type="email"
              name="user"
              id="user"
              value={formData.user || ''}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="password">Contraseña</Label>
            <Input
              type="password"
              name="password"
              id="password"
              value={formData.password || ''}
              onChange={handleChange}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit">
            {initialValues ? 'Actualizar' : 'Crear'}
          </Button>{' '}
          <Button color="secondary" onClick={handleToggle}>
            Cancelar
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default UserForm;
