import { useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import {Collapse,NavbarBrand,Navbar,NavItem,NavLink,Nav,Container,Row,Col,} from "reactstrap";

const Sidebar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState();

  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };

  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  const createLinks = (routes, token) => {
    return routes.map((prop, key) => {
      return (
        <NavItem key={key}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={closeCollapse}
          >
            <i className={prop.icon} />
            {prop.name}
          </NavLink>
        </NavItem>
      );
    });
  };

  const { routes, logo, token } = props;

  return (
    <Navbar
      className="fixed-left navbar-light bg-white"
      expand="md"
      id=""
    >
      <Container fluid>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        <NavbarBrand tag={Link} to="/">
          <img
            alt="Logo"
            className="navbar-brand-img"
            src={require("../../assets/img/Logos/Logo-UTS-1.png")}
            style={{ height: "60px" }}
          />
        </NavbarBrand>
        <Collapse navbar isOpen={collapseOpen}>
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img
                        alt="..."
                        src={require("../../assets/img/Logos/Logo-UTS-1.png")}
                        style={{ height: "60px" }}
                      />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img
                        alt="..."
                        src={require("../../assets/img/Logos/Logo-UTS-1.png")}
                        style={{ height: "60px" }}
                      />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          <Nav navbar>{createLinks(routes, token)}</Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default Sidebar;
