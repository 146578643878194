
import React from "react";
import {Card,CardBody,Container,Row,Col,} from "reactstrap";

// core components
import DemoNavbar from "./HomeNavbar";

class Landing extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            <section className="section section-lg section-shaped pb-200">
              <div className="shape shape-style-1 shape-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container className="py-lg-md d-flex">
                <div className="col mt-5">
                    <Col>
                      <h1 className="display-3 text-white text-center">OBJETIVOS</h1>
                      <p className="lead text-white">
                      Con este proyecto de investigación titulado: Sistema IoT para recolección, almacenamiento y 
                      visualización de datos de variables asociadas a un recurso energético renovable en zona de 
                      alta montaña del corregimiento de Berlín en Tona-Santander
                      </p>
                    </Col>
                </div>
              </Container>
            </section>
          </div>
          <section className="p-5">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-1" md="5">
                  <div className="pr-md-5">
                    <h3 className="text-center">OBJETIVO GENERAL</h3>
                    <p className="font-weight-bold">
                    Desarrollar una solución IoT eficiente y segura para recolección, almacenamiento y 
                    visualización de variables generadas por un recurso energético renovable en zona de alta 
                    montaña de Berlín instalando la infraestructura de comunicaciones necesaria en sitio y programado 
                    el servidor de base de datos y acceso WEB.
                    </p>  
                  </div>
                </Col>
                <Col className="order-md-2" md="7">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("../../assets/img/imagenes/promo-1.jpg")}
                  />
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section pb-0 bg-gradient-warning">
            <Container>
            <h2 className="display-3 text-white text-center">Objetivos especificos</h2>
              <Row className="row-grid align-items-center">
              <Col className="mb-5" lg="5">
                  <Card className="shadow shadow-lg--hover mt-5">
                    <CardBody>
                      <div className="d-flex px-3">
                        <div>
                          <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                            <i className="ni ni-world-2" />
                          </div>
                        </div>
                        <div className="pl-4">
                          <h6>
                        Diseñar una solución de conexión segura y eficiente entre Berlín y 
                        las UTS, que permita la transferencia de información de  
                        manera efectiva y con los niveles de seguridad requeridos.
                          </h6>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <Card className="shadow shadow-lg--hover mt-5">
                    <CardBody>
                      <div className="d-flex px-3">
                        <div>
                          <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                            <i className="ni ni-building" />
                          </div>
                        </div>
                        <div className="pl-4">
                          <h6>
                          Implementar un servidor propio para la gestión de la información y el monitoreo de los 
                          procesos de envío y recepción de datos, garantizando la eficiencia y la seguridad en todo momento.
                          </h6>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <Card className="shadow shadow-lg--hover mt-5">
                    <CardBody>
                      <div className="d-flex px-3">
                        <div>
                          <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                            <i className="ni ni-box-2" />
                          </div>
                        </div>
                        <div className="pl-4">
                          <h6>
                          Desarrollar una base de datos que permita el almacenamiento y gestión de los datos, 
                          asegurando la integridad y la disponibilidad de la información, para su visualización en una 
                          interfaz web creada a la medida.
                          </h6>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="order-lg-2 ml-lg-auto " md="7">
                  <div className="position-relative pl-md-5">
                    <img
                      alt="..."
                      className="img-center img-fluid floating  "
                      src={require("../../assets/img/imagenes/promo-2.jpg")}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
      </>
    );
  }
}

export default Landing;
