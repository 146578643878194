import { Card, CardBody, CardTitle, Container, Row, Col, Button, Modal, ModalBody } from "reactstrap";
import React, { useState, useEffect } from 'react';
import { obtenerDatos5min, exportarExcel } from '../api';

const Header = (props) => {
  const [dateTime, setDateTime] = useState(new Date());
  const [voltaje, setVoltaje] = useState(null);
  const [corriente, setCorriente] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const { sistema } = props;
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  }; 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await obtenerDatos5min(sistema);
        setVoltaje(data.Voltaje);
        setCorriente(data.Corriente);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();

    const intervalId = setInterval(fetchData, 10 * 1000);

    return () => clearInterval(intervalId);
  }, [sistema]); // Este useEffect se ejecutará cuando el prop 'sistema' cambie

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDateTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []); // Este useEffect se ejecutará solo una vez al montar el componente

  const handleExportExcel = async () => {
    try {
      const result = await exportarExcel(sistema);
      console.log(result);
      setModalOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const formattedDateTime = dateTime.toLocaleString();  
  
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-5">
        <Container fluid>
          <div className="header-body"> 
            <Row>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col p-2">
                        <CardTitle tag="h5" className="text-uppercase text-muted mb-0 p-1">
                          Fecha y Hora :  
                        </CardTitle>
                        <span className="h6 font-weight-bold mb-0">
                          {formattedDateTime}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                          <i className="fa-regular fa-clock"/>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h4" className="text-uppercase text-muted mb-0">
                          Voltaje
                        </CardTitle>
                        <span className="h4 font-weight-bold mb-0">{voltaje !== null ? voltaje+'V' : 'Cargando...'}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i className="fa-solid fa-v" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h4" className="text-uppercase text-muted mb-0">
                          Corriente
                        </CardTitle>
                        <span className="h4 font-weight-bold mb-0">{corriente !== null ? corriente+'A' : 'Cargando...'}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                          <i className="fa-solid fa-bolt" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
              <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5" className="text-uppercase text-muted mb-0 p-1">
                          Exportar excel
                        </CardTitle>
                        <Button color="primary"  onClick={handleExportExcel}>Extraer Excel</Button>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                          <i className="fa-solid fa-table" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalBody>
          <p>El Excel ha sido exportado exitosamente.</p>
        </ModalBody>
      </Modal>  
    </>
  );
};

export default Header;
