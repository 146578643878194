import React, { useState, useEffect } from 'react';
import { Line } from "react-chartjs-2";
import { obtenerDatos, obtenerUltimosDatos } from '../api';
import {Card,CardHeader,CardBody,Table,Container,Row,Col,} from "reactstrap";
import Header from "./Header";

const Index = (props) => {
  const { sistema } = props; // Lee el prop someProp
  const [data, setData] = useState([]);
  const [dataU, setDataU] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const fetchedData = await obtenerDatos(sistema);
        const fetchedDataU = await obtenerUltimosDatos(sistema);
        setData(fetchedData);
        setDataU(fetchedDataU);
      } catch (error) {
        console.error('Error al obtener los datos:', error);
      }
    };
  
    getData(); // Llama a getData cuando el prop sistema cambie
  
    const intervalId = setInterval(() => {
      getData(); // Programa la actualización de los datos periódicamente
    }, 10 * 1000);
  
    return () => clearInterval(intervalId); // Limpia el intervalo cuando el componente se desmonte
  }, [sistema]); // Ejecuta el efecto cuando el prop sistema cambie


  const chartData = {
    labels: data.map(entry => `${entry.fecha} ${entry.Hora}`),
    datasets: [
      {
        label: 'Voltaje',
        data: data.map(entry => entry.Voltaje),
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 0.1)',
      },
      {
        label: 'Corriente',
        data: data.map(entry => entry.Corriente),
        borderColor: 'rgba(54, 162, 235, 1)',
        backgroundColor: 'rgba(54, 162, 235, 0.1)',
      },
    ],
  };

  return (
    <>
      <Header sistema={sistema}/>
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" >
            <Card className="bg-gradient-default shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-light ls-1 mb-1">
                      Grafico de Voltaje Y Corriente
                    </h6>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="">
                <Line height={100} data={chartData}/>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="mb-5 mb-xl-0">
            <Card className="shadow">
            <CardHeader className="border-0">
                <h3 className="mb-0">Tabla de Valores</h3>
              </CardHeader>
            <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                  <th scope="col">Fecha</th>
                    <th scope="col">Hora</th>
                    <th scope="col">Voltaje</th>
                    <th scope="col">Diferencia</th>
                    <th scope="col">Corriente</th>
                    <th scope="col">Diferencia</th>
                  </tr>
                </thead>
                <tbody>
                  {dataU.map((entry, index) => (
                    <tr key={index}>
                      <td>{entry.fecha}</td>
                      <td>{entry.Hora}H</td>
                      <td>{entry.Voltaje}V</td>
                      <td style={{ color: entry.VoltajeDiferencia > 0 ? 'green' : 'red' }}>
                        {entry.VoltajeDiferencia}V {entry.VoltajeDiferencia > 0 ? <i className="fas fa-arrow-up" /> : <i className="fas fa-arrow-down" />}
                      </td>
                      <td>{entry.Corriente}A</td>
                      <td style={{ color: entry.CorrienteDiferencia > 0 ? 'green' : 'red' }}>
                        {entry.CorrienteDiferencia}A {entry.CorrienteDiferencia > 0 ? <i className="fas fa-arrow-up" /> : <i className="fas fa-arrow-down" />}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
