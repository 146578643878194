
import React from "react";
import { Container, Row, Col} from "reactstrap";

class Download extends React.Component {
  render() {
    return (
      <>
        <section className="">
          <Container>
            <Row className="row-grid justify-content-center">
              <Col className="text-center">
                <h2 className="display-3"><span className="text-success">INICIO</span></h2>
                <h5 className="font-italic">
                El origen de este proyecto se basa en la idea de ser amigables con el medio ambiente
                y cuidar del mismo, ya que los recursos naturales son los que permiten la existencia del ser humano.<br/>
                </h5>
                <h6>
                Teniendo en cuenta esta premisa, se da lugar a la fusión de dos ingenierías que buscan mitigar 
                el impacto ambiental, en el gasto de energía eléctrica, produciendo un sistema de energía renovable, 
                donde se aprovecha la energía solar y por medio de un sistema de comunicación, se puedan monitorear 
                y almacenar los datos recolectados, utilizando la tecnología que hoy en día nos proporciona IOT.
                </h6>            
                <div className="text-center">
                  <h4 className="display-4 mb-3 mt-3">
                    Semillero de investigacion
                  </h4>
                  <Row className="justify-content-center">
                    <Col lg="2" xs="4">
                    <img
                          alt="..."
                          className="img-fluid"
                          src={require("../../assets/img/Logos/Logo Sistel.png")}
                        />
                    </Col>
                    <Col lg="2" xs="4">
                    <img
                          alt="..."
                          className="img-fluid"
                          src={require("../../assets/img/Logos/Logo Ing Teleco.png")}
                        />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default Download;
