import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";
import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import Index from "components/Home/Index";
import Acercade from "components/Home/Acercade";
import Login from "components/Home/Login";
import Proyecto from "components/Home/Proyecto";
import AdminLayout from "components/DashBoard/Admin";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Routes>
    <Route path="/admin/*" element={<AdminLayout />} />
      <Route path="/" exact element={<Index />} />
      <Route path="/Acercade" exact element={<Acercade />} />
      <Route path="/login" exact element={<Login />} />
      <Route path="/Proyecto" exact element={<Proyecto />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  </BrowserRouter>
);
