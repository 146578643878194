
import React from "react";
import { Card, Container, Col } from "reactstrap";

import DemoNavbar from "./HomeNavbar";
class Profile extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main className="profile-page" ref="main">
          <section className="section-profile-cover section-shaped pt-5" style={{ height: '100%' }}>
            <div className="shape shape-style-1 shape-default alpha-4" >
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Container>
              <Card className="card-profile shadow p-5 mb-5" style={{ marginTop: '50px' }}>
              <Col className="mb-5" >
                      <div className="card-profile-image mb-5 mt-4">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="rounded-circle"
                            src={require("../../assets/img/imagenes/proyecto.jpeg")} height="189px" width="183px"
                          />
                        </a>
                      </div>
                </Col>
                  <div className="">
                    <div className="h4 mt-4"><br/>ENFOQUE DEL PROYECTO</div>
                    <div>
                    El proyecto es desarrollado por dos ingenierías en conjunto, las cuales son:<br/><br/>
                    Ingeniería Eléctrica e Ingeniería de Telecomunicaciones, de las Unidades Tecnológicas de Santander, de la sede Bucaramanga.<br/><br/>
                    El área de Ingeniería eléctrica se encargó de desarrollar un sistema de energía renovable, basado en paneles solares, 
                    para de esta manera y por medio de un sistema de comunicación, diseñado e implementado por parte de la ingeniería de 
                    Telecomunicaciones, se logren recibir los datos de corriente y voltaje generados diariamente por dicho sistema, de igual manera, 
                    se pueden monitorear los datos y posteriormente verlos en tiempo real, por medio de una interfaz web.<br/><br/>
                    De esta manera se tiene un proyecto completo y por primera vez se integran dos áreas de la ingeniería, en nuestra universidad.

                    </div>
                </div>
              </Card>
            </Container>
          </section>
        </main>
      </>
    );
  }
}

export default Profile;
