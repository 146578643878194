
import React from "react";
import {Container, Row, Col, UncontrolledCarousel } from "reactstrap";

const items = [
  {
    src: require("../../assets/img/imagenes/img1.jpg"),
    altText: "",
    caption: "",
    header: "",
  },
  {
    src: require("../../assets/img/imagenes/img2.jpg"),
    altText: "",
    caption: "",
    header: "",
  },
  {
    src: require("../../assets/img/imagenes/img3.jpg"),
    altText: "",
    caption: "",
    header: "",
  },
];

class Carousel extends React.Component {
  render() {
    return (
      <>
        <section className="section section-shaped pb-150">
          <div className="shape shape-style-1 shape-default">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          <Container className="py-md" style={{ marginTop: '50px'}}>
            <Row className="justify-content-between align-items-center">
              <Col className="mb-4 mb-lg-0" lg="4">
                <h3 className=" text-white mt-3">
Sistema IOT para automatizacion de datos de un recurso energetico renovable
                </h3>
              </Col>
              <Col className="mb-lg-auto" lg="8">
                <div className="rounded shadow-lg overflow-hidden transform-perspective-right">
                <UncontrolledCarousel items={items} />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default Carousel;
