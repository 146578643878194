import React from "react";
import { Link } from "react-router-dom";
import Headroom from "headroom.js";
import {UncontrolledCollapse,NavbarBrand,Navbar, NavLink,Nav,Container,Row,Col,} from "reactstrap";

class DemoNavbar extends React.Component {
  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
  }
  state = {
    collapseClasses: "",
    collapseOpen: false,
  };

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out",
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: "",
    });
  };

  render() {
    return (
      <>
        <header className="header-global">
          <Navbar
            className="navbar-main navbar-transparent navbar-light headroom"
            expand="lg"
            id="navbar-main"
          >
            <Container>
              <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                <img
                  alt="..."
                  src={require("../../assets/img/Logos/Logo-UTS-1.png")}
                  style={{ height: '75px' }}
                />
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
                className={this.state.collapseClasses}
                onExiting={this.onExiting}
                onExited={this.onExited}
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                    <Link to="/"><img alt="..."src={require("../../assets/img/Logos/Logo-UTS-1.png")} style={{ height: '75px' }}/></Link>
                    </Col>
                    <Col className="collapse-close" xs="6"><button className="navbar-toggler" id="navbar_global"><span/><span /></button></Col>
                  </Row>
                </div>
                <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                      <NavLink to="/Acercade" tag={Link}>Objetivos</NavLink>
                      <NavLink to="/Proyecto" tag={Link}>Enfoque del proyecto</NavLink>
                </Nav>
                <Nav className="align-items-lg-center ml-lg-auto" navbar>
                <NavLink to="/login" tag={Link}>iniciar sesion</NavLink>
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

export default DemoNavbar;
