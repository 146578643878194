import Index from "./Index.js";
import Usuarios from "./Usuarios.js";

var routes = [
  {
    path: "/sfv1",
    name: "Sistema 1",
    icon: "ni ni-chart-bar-32 text-primary",
    component: <Index sistema="sfvo1"/>,
    layout: "/admin",
  },
  {
    path: "/sfv2",
    name: "Sistema 2",
    icon: "ni ni-chart-bar-32 text-primary",
    component: <Index sistema="sfvo2"/>,
    layout: "/admin",
  },
  {
    path: "/Usuarios",
    name: "Usuarios",
    icon: "ni ni-circle-08 text-red",
    component: <Usuarios/>,
    layout: "/admin",
  },
  {
    path: "",
    name: "cerrar sesion",
    icon: "ni ni-fat-remove text-red",
    component:<></>,
    layout: "/admin/",
  },
];
export default routes;
