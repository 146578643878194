  import React, { useState, useRef } from "react";
  import {Button,Card,CardHeader,CardBody,FormGroup,Form,Input,InputGroupAddon,InputGroupText,InputGroup,Container,Row,Col,} from "reactstrap";
  import DemoNavbar from "./HomeNavbar";
  import { login } from '../api'; // Importa la función de inicio de sesión desde el archivo api.js
  import { useNavigate } from "react-router-dom";

  const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const mainRef = useRef(null); // Usa useRef para crear una referencia

    const handleChange = (event) => {
      const { name, value } = event.target;
      if (name === 'email') {
        setEmail(value);
      } else if (name === 'password') {
        setPassword(value);
      }
    }

    const handleSubmit = async (event) => {
      event.preventDefault();

      try {
        await login(email, password);
        console.log('Inicio de sesión exitoso');
        navigate("/admin/sfv1");
      } catch (error) {
        setError(error.message);
      }
    }

    return (
      <>
        <DemoNavbar />
        <main ref={mainRef}>
          <section className="section section-shaped section-lg" > 
            <div className="shape shape-style-1 bg-gradient-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Container className="pt-lg-6 mt-3">
              <Row className="justify-content-center">
                <Col lg="6">
                  <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-white d-flex justify-content-center">
                      <img
                        alt="..."
                        src={require("../../assets/img/Logos/Logo-UTS-1.png")}
                        style={{ height: '75px' }}
                      />
                    </CardHeader>
                    <CardBody className="px-lg-5">
                      <div className="text-center text-muted mb-5"><h4>Bienvenido</h4></div>
                      <Form role="form" onSubmit={handleSubmit}>
                        <FormGroup className="mb-4">
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-email-83" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Email"
                              type="email"
                              name="email"
                              value={email}
                              onChange={handleChange}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Password"
                              type="password"
                              name="password"
                              value={password}
                              onChange={handleChange}
                              autoComplete="off"
                            />
                          </InputGroup>
                        </FormGroup>
                        {error && <p className="text-center text-danger">{error}</p>}
                        <div className="text-center">
                          <Button className="my-4" color="primary" type="submit">Iniciar Sesión</Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
      </>
    );
  }

  export default Login;
