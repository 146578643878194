const BASE_URL = 'https://www.backend.monitoreotelecoproyectouts.com';


export const obtenerUsuarios = async () => {
  try {
    const response = await fetch(`${BASE_URL}/usuarios`);
    if (!response.ok) {
      throw new Error('Error al obtener usuarios');
    }
    return await response.json();
  } catch (error) {
    console.error('Error al obtener usuarios:', error);
    throw error;
  }
};

// Crear un nuevo usuario
export const crearUsuario = async (nuevoUsuario) => {
  try {
    const response = await fetch(`${BASE_URL}/usuarios`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(nuevoUsuario),
    });
    if (!response.ok) {
      throw new Error('Error al crear usuario');
    }
    return await response.json();
  } catch (error) {
    console.error('Error al crear usuario:', error);
    throw error;
  }
};

// Actualizar un usuario existente
export const actualizarUsuario = async (idUsuario, datosUsuario) => {
  try {
    const response = await fetch(`${BASE_URL}/usuarios/${idUsuario}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(datosUsuario),
    });
    if (!response.ok) {
      throw new Error('Error al actualizar usuario');
    }
    return await response.json();
  } catch (error) {
    console.error('Error al actualizar usuario:', error);
    throw error;
  }
};

// Eliminar un usuario existente
export const eliminarUsuario = async (idUsuario) => {
  try {
    const response = await fetch(`${BASE_URL}/usuarios/${idUsuario}`, {
      method: 'DELETE',
    });
    if (!response.ok) {
      throw new Error('Error al eliminar usuario');
    }
    return await response.json();
  } catch (error) {
    console.error('Error al eliminar usuario:', error);
    throw error;
  }
};

export const login = async (username, password) => {
  try {
    const response = await fetch(`${BASE_URL}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password })
    });

    if (!response.ok) {
      throw new Error('Error al iniciar sesión');
    }
    
    return await response.json();
  } catch (error) {
    throw new Error(error.message);
  }
};

export const obtenerUltimosDatos = async (sistema) => {
  try {
    const response = await fetch(`${BASE_URL}/${sistema}_ultimosdatos`); // Ruta del endpoint que devuelve los últimos datos
    if (!response.ok) {
      throw new Error('Error al obtener los datos');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const obtenerDatos5min = async (sistema) => {
  try {
    const response = await fetch(`${BASE_URL}/${sistema}_5min`);
    if (!response.ok) {
      throw new Error('Error al obtener los datos');
    }
    return await response.json();
  } catch (error) {
    throw new Error(error.message);
  }
};

export const obtenerDatos = async (sistema) => {
  try {
    const response = await fetch(`${BASE_URL}/${sistema}`);
    if (!response.ok) {
      throw new Error('Error al obtener los datos');
    }
    return await response.json();
  } catch (error) {
    throw new Error(error.message);
  }
};

export const exportarExcel = async (sistema) => {
  try {
    const response = await fetch(`${BASE_URL}/${sistema}_exportarexcel`);
    if (!response.ok) {
      throw new Error('Error al exportar a Excel');
    }
    return await response.text();
  } catch (error) {
    throw new Error(error.message);
  }
};

