import React, { useState, useEffect } from 'react';
import {Card,CardHeader,Table,Container,Row,Button,} from 'reactstrap';
import { obtenerUsuarios, crearUsuario, actualizarUsuario, eliminarUsuario } from '../api';
import UserForm from './Form'; // Importa el formulario

const Tables = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    cargarUsuarios();
  }, []);

  const cargarUsuarios = async () => {
    try {
      const data = await obtenerUsuarios();
      setUsuarios(data);
    } catch (error) {
      console.error('Error al cargar usuarios:', error);
    }
  };

  const newform = () => {
    setSelectedUser(null);
    toggleModal();
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleCrearUsuario = async (nuevoUsuario) => {
    try {
      await crearUsuario(nuevoUsuario);
      cargarUsuarios();
      toggleModal();
    } catch (error) {
      console.error('Error al crear usuario:', error);
    }
  };

  const handleActualizarUsuario = async (datosUsuario) => {
    try {
      await actualizarUsuario(selectedUser.Userid, datosUsuario);
      cargarUsuarios();
      toggleModal();
    } catch (error) {
      console.error('Error al actualizar usuario:', error);
    }
  };

  const handleEliminarUsuario = async (idUsuario) => {
    try {
      await eliminarUsuario(idUsuario);
      cargarUsuarios();
    } catch (error) {
      console.error('Error al eliminar usuario:', error);
    }
  };

  const handleEditarUsuario = (usuario) => {
    setSelectedUser(usuario);
    toggleModal();
  };

  const handleToggleModal = () => {
    if (!isOpen) {
      setSelectedUser(null); // Reiniciar el estado de selectedUser cuando se cierra el modal
    }
    toggleModal();
  };

  return (
    <>
    <div className="header bg-gradient-info pb-8 pt-5 pt-md-6"/>
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Tabla de Usuarios</h3>
                <Button color="primary" onClick={newform}>Crear Usuario</Button>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Usuario</th>
                    <th scope="col">Contraseña</th>
                    <th scope="col">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {usuarios.map(usuario => (
                    <tr key={usuario.Userid}>
                      <td>{usuario.user}</td>
                      <td>{usuario.password}</td>
                      <td>
                        <Button color="info" size="sm" onClick={() => handleEditarUsuario(usuario)}>
                          Editar
                        </Button>
                        <Button color="danger" size="sm" onClick={() => handleEliminarUsuario(usuario.Userid)}>
                          Eliminar
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
      <UserForm
        isOpen={isOpen}
        toggle={handleToggleModal}
        onSubmit={selectedUser ? handleActualizarUsuario : handleCrearUsuario}
        initialValues={selectedUser}
      />
    </>
  );
};

export default Tables;
